.wrapper {
	background-color: var(--font-brand-green);
	position: relative;
	padding: 80px;
	min-height: 560px;
}

.wrapper > svg {
	position: absolute;
	top: -1px;
	left: 0;
	z-index: 1;
	stroke: var(--light-green);
}

.contentContainer {
	display: flex;
	flex-direction: column;
	gap: 40px;
	justify-content: center;
	align-items: center;
}

.contentContainer * {
	z-index: 10;
}

.socialContainer {
	display: flex;
	gap: 30px;
}

.socialContainer svg {
	color: var(--font-light);
	transition: all 0.2s ease-in-out;
}

.socialContainer svg:hover {
	transform: scale(1.1);
}

.navContainer {
	display: flex;
	gap: 40px;
}

.link {
	color: var(--font-light);
}

.copyright {
	color: var(--font-light);
	font-size: 14px;
	margin-top: 80px;
}

@media screen and (max-width: 768px) {
	.wrapper {
		padding: 80px 20px;
	}
}
