.wrapper {
	display: flex;
	flex-direction: column;
}

.headingContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 40px;
	padding: 80px 25% 0;
	text-align: center;
}

.headingContainer > *:last-child {
	color: var(--font-dark);
}

.columnWrapper {
	display: flex;
}

.imageContainer {
	width: 50%;
}

.imageContainer img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contentContainer {
	width: 50%;
	padding: 80px 15% 80px 5%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 20px;
}

@media screen and (max-width: 768px) {
	.columnWrapper {
		flex-direction: column;
	}

	.headingContainer {
		padding: 80px 20px 40px;
		gap: 20px;
	}
	.contentContainer {
		width: 100%;
		padding: 40px 20px 80px;
		align-items: center;
	}

	.imageContainer {
		width: 100%;
	}
}
