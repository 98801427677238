.wrapper {
	padding: 80px 15%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #fff;
}

.container {
	background-color: var(--background-light);
	padding: 40px;
	width: 100%;
	max-width: 900px;
}

.headingContainer {
	display: flex;
	justify-content: space-between;
}

.headingContainer h4 {
	font-weight: bold;
}

.stepContainer {
	display: flex;
	color: #bbbbbb;
	gap: 60px;
}

.step {
	display: flex;
	align-items: center;
	gap: 10px;
	position: relative;
}

.step div {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	font-size: 15px;
	height: 30px;
	background-color: #e4e4e4;
	border-radius: 50%;
}

.step:not(:last-child) p::after {
	content: "";
	border-bottom: 2px solid #e4e4e4;
	margin-left: 10px;
	width: 40px;
	position: absolute;
	top: 50%;
}

[data-active-step="true"] {
	background-color: var(--font-brand-green) !important;
	color: #fff;
}

.inputContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin: 20px 0;
}

.inputContainer input {
	width: 100%;
	font-size: 16px;
}

.addressInput {
	width: 100%;
}

.addressInput:focus {
	outline: none;
	box-shadow: var(--small-shadow);
}

.suggestionContainer {
	background-color: var(--background-light);
	background-color: #fff;
	padding: 12px 16px;
	border-radius: 15px;
	margin-top: -32px;
}

.suggestionContainer * {
	margin-bottom: 6px;
}

.suggestionContainer > *:hover {
	border-bottom: 1px solid var(--background-light);
}

.suggestionContainer *:first-child {
	margin-top: 32px;
}

.suggestionContainer span {
	cursor: pointer;
}

.btnContainer {
	display: flex;
	justify-content: flex-end;
}

.backBtn {
	margin-right: auto;
	background-color: #bbbbbb;
}

@media screen and (max-width: 1024px) {
	.wrapper {
		padding: 40px;
	}
}

@media screen and (max-width: 768px) {
	.wrapper {
		padding: 0;
	}

	.container {
		padding: 60px 20px;
	}

	.headingContainer {
		flex-direction: column;
		gap: 40px;
	}

	.stepContainer {
		justify-content: center;
		gap: 30px;
	}

	.step {
		flex-direction: column;
	}

	.step:not(:last-child) p::after {
		display: none;
	}

	.inputContainer {
		flex-direction: column;
		gap: 0;
	}
}
