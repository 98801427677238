.wrapper {
	overflow: hidden;
	display: flex;
	justify-content: center;
	margin-bottom: -50px;
}

.wrapper img {
	width: 120%;
	z-index: 10;
}
