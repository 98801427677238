@font-face {
	font-family: "Gilroy";
	src: local("Gilroy"), url("./fonts/Gilroy-Medium.ttf") format("truetype");
	font-weight: normal;
	font-display: swap;
}

@font-face {
	font-family: "Gilroy";
	src: local("Gilroy"), url("./fonts/Gilroy-Bold.ttf") format("truetype");
	font-weight: bold;
	font-display: swap;
}

:root {
	--font-light: #fff;
	--font-brand-green: #2e8935;
	--font-dark: #1d3818;
	--background-light: #f5f5f5;
	--background-green: #026c39;
	--light-green: #a2bf65;
	--small-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
		rgba(0, 0, 0, 0.23) 0px 3px 6px;
	--large-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
		rgba(0, 0, 0, 0.22) 0px 10px 10px;
	--inside-shadow: rgba(0, 0, 0, 0.5) 0px 2px 10px 0px inset;
	--border-radius: 50px;
	--thin-border: 1px;
	--thick-border: 3px;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Gilroy", sans-serif;
	font-weight: normal;
	letter-spacing: 1px;
}

html {
	background-color: var(--font-brand-green);
}

a {
	color: var(--font-brand-green);
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	color: var(--font-dark);
}

input,
select,
textarea,
button,
.btn {
	border-radius: 15px;
	border: 0;
	padding: 12px 16px;
	margin: 10px 0;
}

button,
.btn {
	display: inline-block;
	background-color: var(--font-dark);
	color: var(--font-light);
	font-weight: bold;
	font-size: 18px;
	cursor: pointer;
	padding: 16px 40px;
	border-radius: var(--border-radius);
}

button:hover,
.btn:hover {
	transform: scale(0.99);
}

.wrapper {
	padding: 100px 5%;
}

@media screen and (max-width: 768px) {
	.wrapper {
		padding: 80px 20px;
	}
}

/* App wide font sizes and font media queries */

h1 {
	font-size: 80px;
}

h2 {
	font-size: 50px;
}

h3 {
	font-size: 40px;
}

h4 {
	font-size: 30px;
}

h5 {
	font-size: 20px;
}

p {
	font-size: 16px;
	line-height: 1.5;
}

strong {
	font-weight: bold;
}

@media screen and (max-width: 768px) {
	h1 {
		font-size: 50px;
		text-align: center;
	}

	h2 {
		font-size: 40px;
		text-align: center;
	}

	h3 {
		font-size: 30px;
		text-align: center;
	}

	h4 {
		font-size: 22px;
		text-align: center;
	}

	h5 {
		font-size: 18px;
		text-align: center;
	}

	p {
		font-size: 16px;
		text-align: center;
	}
}

.App {
	background: var(--background-light);
	color: var(--font-brand-green);
	min-height: 100vh;
}

.loading {
	color: #b3b3b3;
	min-height: 25vh;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 80px;
}
