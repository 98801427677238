.wrapper {
	padding: 100px 15%;
}

.gridContainer {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 40px;
	margin-top: 40px;
}

.card {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 20px;
}

.card img {
	width: 100%;
	object-fit: cover;
}

.cardText * {
	margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
	.wrapper {
		padding: 80px 20px;
	}
}
