.wrapper {
	padding: 80px 5%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form {
	display: flex;
	flex-direction: column;
	width: 50%;
	max-width: 600px;
	justify-content: center;
}

.form input,
.form textarea,
.form select {
	font-size: 16px;
}

.form input:focus,
.form textarea:focus,
.form select:focus {
	outline-color: var(--light-green);
}

.wrapper form button {
	align-self: flex-start;
}

.form a:hover {
	color: var(--font-dark);
}

@media screen and (max-width: 768px) {
	.wrapper {
		flex-direction: column;
		gap: 40px;
	}

	.form {
		align-items: center;
		width: 100%;
	}

	.form * {
		width: 100%;
	}
}
