.wrapper {
	padding: 100px 5%;
	display: grid;
	gap: 5%;
	background-color: var(--font-brand-green);
	color: var(--font-light);
}

.evenGrid {
	grid-template-columns: 1fr 1fr;
}

.rightGrid {
	grid-template-columns: 1fr 3fr;
}

.leftContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.leftContainer img {
	width: 20%;
	height: auto;
}

.rightContainer > * {
	margin-bottom: 20px;
}

.rightContainer > *:last-child {
	margin-bottom: 0;
}

.rightContainer ul,
.rightContainer ol {
	margin-left: 30px;
	margin-right: 10px;
}

.rightContainer li {
	margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
	.wrapper {
		grid-template-columns: 1fr;
		gap: 40px;
		padding: 80px 20px;
	}

	.leftContainer {
		flex-direction: column;
	}

	.leftContainer img {
		width: 30%;
	}

	.rightContainer * {
		margin-bottom: 40px;
	}
}
