.wrapper {
	width: 100vw;
	overflow: hidden;
	position: absolute;
	height: 120px;
}

.logo {
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	height: 100%;
	padding: 20px;
	z-index: 10;
	margin-top: 20px;
}

.logo img {
	height: 100%;
}

.container {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	padding: 20px 5%;
	gap: 40px;
	transition: transform 0.3s ease-in-out;
}

.link {
	font-weight: bold;
	font-size: 18px;
	padding-bottom: 8px;
}

.whiteLink {
	font-weight: bold;
	font-size: 18px;
	padding-bottom: 8px;
	color: var(--font-light);
}

.link:hover {
	color: var(--font-brand-green);
	border-bottom: 1px solid var(--font-brand-green);
}

.whiteLink:hover {
	color: var(--font-light);
	border-bottom: 1px solid var(--font-light);
}

.mobileMenuBtn {
	position: absolute;
	top: 40px;
	right: 40px;
	transition: transform 0.3s ease-in-out;
	display: none;
	z-index: 999;
}

.mobileMenuBtn:hover {
	transform: rotate(90deg);
	cursor: pointer;
}

@media screen and (max-width: 1024px) {
	.container {
		width: 40%;
		min-width: 180px;
		max-width: 300px;
		background-color: #333333;
		position: fixed;
		right: 0;
		height: 100%;
		flex-direction: column;
		padding: 120px 5%;
		justify-content: flex-start;
		gap: 30px;
		z-index: 999;
		color: var(--font-light);
	}

	.link {
		color: var(--font-light);
	}

	.mobileMenuBtn {
		display: block;
	}
}
