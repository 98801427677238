.wrapper {
	background-color: var(--font-brand-green);
	padding: 80px 5%;
	display: grid;
	grid-template-columns: 1fr 2fr;
}

.headingContainer {
	color: var(--font-light);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 15%;
}

.headingContainer p {
	margin-top: 20px;
}

.comparisonContainer {
	background-color: var(--background-light);
	color: var(--font-brand-green);
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	justify-content: center;
	justify-items: center;
	align-items: flex-start;
	padding: 80px;
}

.comparisonContainer span {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #707070;
	border-radius: 50%;
	font-weight: bold;
	align-self: center;
	height: 60px;
	width: 60px;
}

.comparisonContainer ul {
	margin-top: 40px;
	list-style: none;
}

.comparisonContainer li {
	margin-bottom: 15px;
}

@media screen and (max-width: 768px) {
	.wrapper {
		grid-template-columns: 1fr;
		gap: 40px;
	}

	.headingContainer {
		margin-right: unset;
	}

	.comparisonContainer {
		grid-template-columns: 1fr;
		padding: 40px 20px;
		gap: 40px;
		text-align: center;
	}
}
