.wrapper {
	padding: 80px 5%;
}

.headingContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
}

.headingContainer p {
	margin-top: 20px;
}

.step {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.stepText {
	margin-top: 80px;
	margin-right: 30%;
}

.step:nth-child(even) .stepText {
	order: 1;
	margin-right: 10%;
	margin-left: 30%;
}

.stepTitle {
	padding-bottom: 20px;
	border-bottom: 1px solid #707070;
	margin-bottom: 30px;
}

.step img {
	width: 100%;
	height: auto;
	padding-right: 10%;
}

.step:nth-child(even) img {
	padding-left: 10%;
	padding-right: unset;
}

@media screen and (max-width: 768px) {
	.headingContainer {
		margin-bottom: 0;
	}
	.step {
		grid-template-columns: 1fr;
	}

	.step img {
		padding: 0;
	}

	.step:nth-child(even) img {
		padding: 0;
	}

	.stepText {
		margin: 40px 0;
	}

	.step:nth-child(even) .stepText {
		order: unset;
		margin: 80px 0 40px;
	}
}
