.wrapper {
	min-height: 100vh;
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 120px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container {
	width: 50%;
	color: var(--font-light);
}

@media screen and (max-width: 768px) {
	.wrapper {
		flex-direction: column;
		justify-content: center;
		gap: 40px;
	}

	.container {
		width: 100%;
		text-align: center;
	}
}
